import { useOidcUser } from '@axa-fr/react-oidc';
import { Badge } from '@mantine/core';
import { RequestStateType } from '@shared/api/models';
import { useGetRequests } from '@shared/api/queries/requests/requests';
import { LoadingAnimation, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import TimeAgo from 'timeago-react';

const getBadgeVariant = (state: RequestStateType): string => {
    switch (state) {
        case RequestStateType.DataVerification:
        case RequestStateType.SignatureVerification:
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.ServiceActivation:
            return variants.badge.primary;
        case RequestStateType.Invited:
        case RequestStateType.AdditionalDataInput:
        case RequestStateType.DataInput:
        case RequestStateType.ContractSigning:
            return variants.badge.warning;
        case RequestStateType.Completed:
            return variants.badge.success;
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return variants.badge.alert;
        default:
            return '';
    }
};

export const ManageRequests = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: requestsResponse, isLoading: isRequestsLoading } = useGetRequests();
    const { oidcUser } = useOidcUser();
    const requests = requestsResponse?.data;

    if (isRequestsLoading) {
        return <LoadingAnimation />;
    }

    const handleClick = (requestId: string) => {
        if (requestId === 'newRequest') {
            return navigate('/selectCustomer');
        }
        navigate(`/request/${requestId}`);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col">
                <h1 className="text-4xl md:p-0">{t('welcomeBack')}</h1>
                <p className="text-base text-text-light">{t('whichCompanyRegistration')}</p>
            </div>
            <div className="flex flex-col gap-4">
                {requests?.map((request) => (
                    <button
                        key={request.id}
                        className="card card-clickable w-full p-4 shadow-none"
                        value={request.id}
                        onClick={() => handleClick(request.id)}
                    >
                        <div className="flex w-full justify-between gap-base p-2">
                            <div className="text-base flex-col">
                                <div className="flex justify-start">
                                    {request.companyData?.companyName && (
                                        <h6 className="font-base text-left">{request.companyData?.companyName}</h6>
                                    )}
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="text-sm flex gap-1 text-text-light">
                                        {request.currentState && (
                                            <Badge variant={getBadgeVariant(request.currentState)}>
                                                {t(`RequestState.${request.currentState}`)}
                                            </Badge>
                                        )}
                                        {request.currentState && request.modifiedDate && <span>|</span>}
                                        {request.modifiedDate && (
                                            <span>
                                                <TimeAgo datetime={request.modifiedDate} locale={oidcUser.locale} />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <span className="text-xs self-start text-text-ultra-light">{`${t('createdOn')} ${new Date(request.createdDate).toLocaleDateString(oidcUser.locale)}`}</span>
                            </div>
                        </div>
                    </button>
                ))}
            </div>
            <div className="flex justify-center gap-1">
                <p className="text-base">{t('otherCompany')}</p>
                <button className="text-base text-text-accent" onClick={() => handleClick('newRequest')}>
                    {t('startNewRegistration')}
                </button>
            </div>
        </div>
    );
};
