/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetStepsResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of timeline steps
 */
export const getSteps = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetStepsResponse>(
      {url: `/requests/${id}/steps`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStepsQueryKey = (id: string,) => {
    return [`/requests/${id}/steps`] as const;
    }

    
export const getGetStepsQueryOptions = <TData = Awaited<ReturnType<typeof getSteps>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSteps>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStepsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSteps>>> = ({ signal }) => getSteps(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSteps>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetStepsQueryResult = NonNullable<Awaited<ReturnType<typeof getSteps>>>
export type GetStepsQueryError = ErrorType<ProblemDetails>


export function useGetSteps<TData = Awaited<ReturnType<typeof getSteps>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSteps>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSteps>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSteps<TData = Awaited<ReturnType<typeof getSteps>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSteps>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSteps>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSteps<TData = Awaited<ReturnType<typeof getSteps>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSteps>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetSteps<TData = Awaited<ReturnType<typeof getSteps>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSteps>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetStepsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



