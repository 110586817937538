import bgShared from '@shared/translations/bg.json';
import csShared from '@shared/translations/cs.json';
import daShared from '@shared/translations/da.json';
import deShared from '@shared/translations/de.json';
import elShared from '@shared/translations/el.json';
import enGBShared from '@shared/translations/en-GB.json';
import enShared from '@shared/translations/en.json';
import esUSShared from '@shared/translations/es-US.json';
import esShared from '@shared/translations/es.json';
import etShared from '@shared/translations/et.json';
import fiShared from '@shared/translations/fi.json';
import frShared from '@shared/translations/fr.json';
import hrShared from '@shared/translations/hr.json';
import huShared from '@shared/translations/hu.json';
import itShared from '@shared/translations/it.json';
import ltShared from '@shared/translations/lt.json';
import lvShared from '@shared/translations/lv.json';
import nbShared from '@shared/translations/nb.json';
import nlShared from '@shared/translations/nl.json';
import plShared from '@shared/translations/pl.json';
import ptShared from '@shared/translations/pt.json';
import roShared from '@shared/translations/ro.json';
import ruShared from '@shared/translations/ru.json';
import skShared from '@shared/translations/sk.json';
import slShared from '@shared/translations/sl.json';
import srShared from '@shared/translations/sr.json';
import svShared from '@shared/translations/sv.json';
import trShared from '@shared/translations/tr.json';
import ukShared from '@shared/translations/uk.json';
import i18next, { ParseKeys, use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import bg from './translations/bg.json';
import cs from './translations/cs.json';
import da from './translations/da.json';
import de from './translations/de.json';
import el from './translations/el.json';
import enGB from './translations/en-GB.json';
import en from './translations/en.json';
import esUS from './translations/es-US.json';
import es from './translations/es.json';
import et from './translations/et.json';
import fi from './translations/fi.json';
import fr from './translations/fr.json';
import hr from './translations/hr.json';
import hu from './translations/hu.json';
import it from './translations/it.json';
import lt from './translations/lt.json';
import lv from './translations/lv.json';
import nb from './translations/nb.json';
import nl from './translations/nl.json';
import pl from './translations/pl.json';
import pt from './translations/pt.json';
import ro from './translations/ro.json';
import ru from './translations/ru.json';
import sk from './translations/sk.json';
import sl from './translations/sl.json';
import sr from './translations/sr.json';
import sv from './translations/sv.json';
import tr from './translations/tr.json';
import uk from './translations/uk.json';

const getVariationOfAOrAn = (value: string, capitalize: boolean) => {
    const letters = ['A', 'E', 'I', 'O', 'U', 'H', 'a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = value.substring(0, 1);
    if (
        letters.find(function (l) {
            return firstLetter === l;
        })
    ) {
        return capitalize ? 'An' : 'an';
    }
    return capitalize ? 'A' : 'a';
};

export const defaultNS = 'translations';

export const resources = {
    'bg': { Name: 'Български', translations: { ...bg, ...bgShared } },
    'cs': { Name: 'Čeština', translations: { ...cs, ...csShared } },
    'da': { Name: 'Dansk', translations: { ...da, ...daShared } },
    'de': { Name: 'Deutsch', translations: { ...de, ...deShared } },
    'el': { Name: 'Ελληνικά', translations: { ...el, ...elShared } },
    'en': { Name: 'English', translations: { ...en, ...enShared } },
    'en-GB': { Name: 'English (UK)', translations: { ...enGB, ...enGBShared } },
    'en-US': { Name: 'English (US)', translations: { ...en, ...enShared } },
    'es-US': { Name: 'Español (Estados Unidos)', translations: { ...esUS, ...esUSShared } },
    'es': { Name: 'Español', translations: { ...es, ...esShared } },
    'et': { Name: 'Eesti', translations: { ...et, ...etShared } },
    'fi': { Name: 'Suomi', translations: { ...fi, ...fiShared } },
    'fr': { Name: 'Français', translations: { ...fr, ...frShared } },
    'hr': { Name: 'Hrvatski', translations: { ...hr, ...hrShared } },
    'hu': { Name: 'Magyar', translations: { ...hu, ...huShared } },
    'it': { Name: 'Italiano', translations: { ...it, ...itShared } },
    'lt': { Name: 'Lietuvių', translations: { ...lt, ...ltShared } },
    'lv': { Name: 'Latviešu', translations: { ...lv, ...lvShared } },
    'nb': { Name: 'Norsk Bokmål', translations: { ...nb, ...nbShared } },
    'nl': { Name: 'Nederlands', translations: { ...nl, ...nlShared } },
    'pl': { Name: 'Polski', translations: { ...pl, ...plShared } },
    'pt': { Name: 'Português', translations: { ...pt, ...ptShared } },
    'ro': { Name: 'Română', translations: { ...ro, ...roShared } },
    'ru': { Name: 'Русский', translations: { ...ru, ...ruShared } },
    'sk': { Name: 'Slovenčina', translations: { ...sk, ...skShared } },
    'sl': { Name: 'Slovenščina', translations: { ...sl, ...slShared } },
    'sr': { Name: 'Српски', translations: { ...sr, ...srShared } },
    'sv': { Name: 'Svenska', translations: { ...sv, ...svShared } },
    'tr': { Name: 'Türkçe', translations: { ...tr, ...trShared } },
    'uk': { Name: 'Українська', translations: { ...uk, ...ukShared } }
};

// eslint-disable-next-line react-hooks/rules-of-hooks
use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: ['en-US'],
        detection: {
            order: ['cookie', 'localStorage', 'navigator'],
            caches: ['cookie', 'localStorage']
        },
        defaultNS,
        resources,
        returnNull: false,
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                if (format === 'lowercase') {
                    return value.toLowerCase();
                }
                if (format === 'en-handle-an') {
                    return !lng || lng === 'en' ? getVariationOfAOrAn(value, false) : '';
                }
                if (format === 'en-handle-an-capitalized') {
                    return !lng || lng === 'en' ? getVariationOfAOrAn(value, true) : '';
                }
                if (format === 'en-handle-lowercase') {
                    return !lng || lng === 'en' ? value.toLowerCase() : value;
                }
                return value;
            }
        }
    });

declare module 'i18next' {
    interface CustomTypeOptions {
        defaultNS: typeof defaultNS;
        resources: (typeof resources)['en'];
        returnNull: false;
    }
}

export type TranslationKeys = ParseKeys<'translations'>;

export const i18n = i18next;
