import { clsx } from 'clsx';
import { ReactNode } from 'react';

type InformationProps = {
    children: ReactNode;
    className?: string;
};

export const Information = ({ children, className }: InformationProps) => (
    <div className={clsx('flex flex-col gap-4 p-4 text-sm', className)}>{children}</div>
);

type InformationSectionProps = {
    title: string;
    children: ReactNode;
    className?: string;
};

export const InformationSection = ({ title, children, className }: InformationSectionProps) => (
    <div className={className}>
        <div className="flex flex-col">
            <h6 className="text-base">{title}</h6>
            {children}
        </div>
    </div>
);
