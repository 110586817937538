import { secureRoute } from '@uag/react-core';
import { Route, Routes } from 'react-router';

import { ManageRequests } from 'app/request/ManageRequests';
import { OnboardingFrame as RequestFrame } from 'app/request/OnboardingFrame';
import { SelectCustomerView } from 'app/request/SelectCustomerView';
import { Home } from './Home';
import { NotFound } from './NotFound';
import { Request } from './request/Request';
import { RequestNotFoundExpired } from './request/RequestNotFound';
import { Invitation } from './start/Invitation';
import { StartLayoutFrame } from './start/StartLayoutFrame';
import { Unauthorized } from './Unauthorized';

const SecureRequestFrame = secureRoute(RequestFrame);
const SecureStartLayoutFrame = secureRoute(StartLayoutFrame);

export const AppRoutes = () => {
    return (
        <Routes>
            <Route Component={SecureRequestFrame} path="/">
                <Route Component={Home} path="" />
                <Route Component={Request} path="request/:requestId" />
            </Route>
            <Route Component={SecureStartLayoutFrame}>
                <Route Component={SelectCustomerView} path="selectCustomer" />
                <Route Component={ManageRequests} path="manageRequests" />
                <Route Component={Invitation} path="invitation/:requestId" />
                <Route Component={RequestNotFoundExpired} path="request/not-found-expired" />
            </Route>
            <Route Component={Unauthorized} path="/unauthorized" />
            <Route Component={NotFound} path="*" />
        </Routes>
    );
};
