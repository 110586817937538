/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ContractSigningCompletedCallbackParams,
  GetContractSigningLinkResponse,
  GetSignedContractFilesResponse,
  ProblemDetails,
  SigningCompletedModel,
  VerifySignatureModel
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a link to sign the contract
 */
export const getContractSigningLink = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetContractSigningLinkResponse>(
      {url: `/requests/${id}/contracts/signing-link`, method: 'GET', signal
    },
      options);
    }
  

export const getGetContractSigningLinkQueryKey = (id: string,) => {
    return [`/requests/${id}/contracts/signing-link`] as const;
    }

    
export const getGetContractSigningLinkQueryOptions = <TData = Awaited<ReturnType<typeof getContractSigningLink>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContractSigningLink>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContractSigningLinkQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContractSigningLink>>> = ({ signal }) => getContractSigningLink(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContractSigningLink>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetContractSigningLinkQueryResult = NonNullable<Awaited<ReturnType<typeof getContractSigningLink>>>
export type GetContractSigningLinkQueryError = ErrorType<ProblemDetails>


export function useGetContractSigningLink<TData = Awaited<ReturnType<typeof getContractSigningLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContractSigningLink>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getContractSigningLink>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetContractSigningLink<TData = Awaited<ReturnType<typeof getContractSigningLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContractSigningLink>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getContractSigningLink>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetContractSigningLink<TData = Awaited<ReturnType<typeof getContractSigningLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContractSigningLink>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetContractSigningLink<TData = Awaited<ReturnType<typeof getContractSigningLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContractSigningLink>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetContractSigningLinkQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Callback url for docusign service
 */
export const contractSigningCompletedCallback = (
    id: string,
    signingCompletedModel: SigningCompletedModel,
    params: ContractSigningCompletedCallbackParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/contracts/signing-callback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signingCompletedModel,
        params, signal
    },
      options);
    }
  


export const getContractSigningCompletedCallbackMutationOptions = <TData = Awaited<ReturnType<typeof contractSigningCompletedCallback>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: SigningCompletedModel;params: ContractSigningCompletedCallbackParams}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['contractSigningCompletedCallback'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contractSigningCompletedCallback>>, {id: string;data: SigningCompletedModel;params: ContractSigningCompletedCallbackParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  contractSigningCompletedCallback(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: SigningCompletedModel;params: ContractSigningCompletedCallbackParams}, TContext>}

    export type ContractSigningCompletedCallbackMutationResult = NonNullable<Awaited<ReturnType<typeof contractSigningCompletedCallback>>>
    export type ContractSigningCompletedCallbackMutationBody = SigningCompletedModel
    export type ContractSigningCompletedCallbackMutationError = ErrorType<ProblemDetails>

    export const useContractSigningCompletedCallback = <TData = Awaited<ReturnType<typeof contractSigningCompletedCallback>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: SigningCompletedModel;params: ContractSigningCompletedCallbackParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string;data: SigningCompletedModel;params: ContractSigningCompletedCallbackParams},
        TContext
      > => {

      const mutationOptions = getContractSigningCompletedCallbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a list of files of a signed contract
 */
export const getSignedContractFiles = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetSignedContractFilesResponse>(
      {url: `/requests/${id}/contracts/signed`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSignedContractFilesQueryKey = (id: string,) => {
    return [`/requests/${id}/contracts/signed`] as const;
    }

    
export const getGetSignedContractFilesQueryOptions = <TData = Awaited<ReturnType<typeof getSignedContractFiles>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContractFiles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSignedContractFilesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSignedContractFiles>>> = ({ signal }) => getSignedContractFiles(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSignedContractFiles>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetSignedContractFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getSignedContractFiles>>>
export type GetSignedContractFilesQueryError = ErrorType<ProblemDetails>


export function useGetSignedContractFiles<TData = Awaited<ReturnType<typeof getSignedContractFiles>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContractFiles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSignedContractFiles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSignedContractFiles<TData = Awaited<ReturnType<typeof getSignedContractFiles>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContractFiles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSignedContractFiles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSignedContractFiles<TData = Awaited<ReturnType<typeof getSignedContractFiles>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContractFiles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetSignedContractFiles<TData = Awaited<ReturnType<typeof getSignedContractFiles>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContractFiles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetSignedContractFilesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the content of a file of a signed contract 
 */
export const downloadContractFileContent = (
    id: string,
    fileid: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<Blob>(
      {url: `/requests/${id}/contracts/signed/${fileid}/download`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getDownloadContractFileContentQueryKey = (id: string,
    fileid: string,) => {
    return [`/requests/${id}/contracts/signed/${fileid}/download`] as const;
    }

    
export const getDownloadContractFileContentQueryOptions = <TData = Awaited<ReturnType<typeof downloadContractFileContent>>, TError = ErrorType<ProblemDetails>>(id: string,
    fileid: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFileContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadContractFileContentQueryKey(id,fileid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadContractFileContent>>> = ({ signal }) => downloadContractFileContent(id,fileid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && fileid), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadContractFileContent>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type DownloadContractFileContentQueryResult = NonNullable<Awaited<ReturnType<typeof downloadContractFileContent>>>
export type DownloadContractFileContentQueryError = ErrorType<ProblemDetails>


export function useDownloadContractFileContent<TData = Awaited<ReturnType<typeof downloadContractFileContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    fileid: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFileContent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadContractFileContent>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadContractFileContent<TData = Awaited<ReturnType<typeof downloadContractFileContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    fileid: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFileContent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadContractFileContent>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadContractFileContent<TData = Awaited<ReturnType<typeof downloadContractFileContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    fileid: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFileContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useDownloadContractFileContent<TData = Awaited<ReturnType<typeof downloadContractFileContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    fileid: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFileContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getDownloadContractFileContentQueryOptions(id,fileid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Verify the signature of a contract
 */
export const verifyContractSignature = (
    id: string,
    verifySignatureModel: VerifySignatureModel,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/contracts/verify-signature`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifySignatureModel, signal
    },
      options);
    }
  


export const getVerifyContractSignatureMutationOptions = <TData = Awaited<ReturnType<typeof verifyContractSignature>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: VerifySignatureModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['verifyContractSignature'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyContractSignature>>, {id: string;data: VerifySignatureModel}> = (props) => {
          const {id,data} = props ?? {};

          return  verifyContractSignature(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: VerifySignatureModel}, TContext>}

    export type VerifyContractSignatureMutationResult = NonNullable<Awaited<ReturnType<typeof verifyContractSignature>>>
    export type VerifyContractSignatureMutationBody = VerifySignatureModel
    export type VerifyContractSignatureMutationError = ErrorType<ProblemDetails>

    export const useVerifyContractSignature = <TData = Awaited<ReturnType<typeof verifyContractSignature>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: VerifySignatureModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string;data: VerifySignatureModel},
        TContext
      > => {

      const mutationOptions = getVerifyContractSignatureMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a zip of files of a signed contract
 */
export const downloadContractFilesZipContent = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<Blob>(
      {url: `/requests/${id}/contracts/signed/download`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getDownloadContractFilesZipContentQueryKey = (id: string,) => {
    return [`/requests/${id}/contracts/signed/download`] as const;
    }

    
export const getDownloadContractFilesZipContentQueryOptions = <TData = Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadContractFilesZipContentQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadContractFilesZipContent>>> = ({ signal }) => downloadContractFilesZipContent(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type DownloadContractFilesZipContentQueryResult = NonNullable<Awaited<ReturnType<typeof downloadContractFilesZipContent>>>
export type DownloadContractFilesZipContentQueryError = ErrorType<ProblemDetails>


export function useDownloadContractFilesZipContent<TData = Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadContractFilesZipContent>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadContractFilesZipContent<TData = Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadContractFilesZipContent>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadContractFilesZipContent<TData = Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useDownloadContractFilesZipContent<TData = Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadContractFilesZipContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getDownloadContractFilesZipContentQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



