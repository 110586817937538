/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetAttachmentByIdResponse,
  GetAttachmentsParams,
  GetAttachmentsResponse,
  ProblemDetails,
  UploadAttachmentContentBody
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of attachments
 */
export const getAttachments = (
    id: string,
    params?: GetAttachmentsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetAttachmentsResponse>(
      {url: `/requests/${id}/attachments`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAttachmentsQueryKey = (id: string,
    params?: GetAttachmentsParams,) => {
    return [`/requests/${id}/attachments`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAttachmentsQueryOptions = <TData = Awaited<ReturnType<typeof getAttachments>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetAttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAttachmentsQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttachments>>> = ({ signal }) => getAttachments(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAttachments>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getAttachments>>>
export type GetAttachmentsQueryError = ErrorType<ProblemDetails>


export function useGetAttachments<TData = Awaited<ReturnType<typeof getAttachments>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetAttachmentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAttachments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAttachments<TData = Awaited<ReturnType<typeof getAttachments>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetAttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAttachments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAttachments<TData = Awaited<ReturnType<typeof getAttachments>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetAttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetAttachments<TData = Awaited<ReturnType<typeof getAttachments>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetAttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetAttachmentsQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns an attachment by id
 */
export const getAttachmentById = (
    id: string,
    attachmentId: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetAttachmentByIdResponse>(
      {url: `/requests/${id}/attachments/${attachmentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAttachmentByIdQueryKey = (id: string,
    attachmentId: string,) => {
    return [`/requests/${id}/attachments/${attachmentId}`] as const;
    }

    
export const getGetAttachmentByIdQueryOptions = <TData = Awaited<ReturnType<typeof getAttachmentById>>, TError = ErrorType<ProblemDetails>>(id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachmentById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAttachmentByIdQueryKey(id,attachmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttachmentById>>> = ({ signal }) => getAttachmentById(id,attachmentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && attachmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAttachmentById>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetAttachmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAttachmentById>>>
export type GetAttachmentByIdQueryError = ErrorType<ProblemDetails>


export function useGetAttachmentById<TData = Awaited<ReturnType<typeof getAttachmentById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachmentById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAttachmentById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAttachmentById<TData = Awaited<ReturnType<typeof getAttachmentById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachmentById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAttachmentById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAttachmentById<TData = Awaited<ReturnType<typeof getAttachmentById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachmentById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetAttachmentById<TData = Awaited<ReturnType<typeof getAttachmentById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttachmentById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetAttachmentByIdQueryOptions(id,attachmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Uploads content to an attachment
 */
export const uploadAttachmentContent = (
    id: string,
    attachmentId: string,
    uploadAttachmentContentBody: UploadAttachmentContentBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', uploadAttachmentContentBody.File)

      return customMutator<void>(
      {url: `/requests/${id}/attachments/${attachmentId}/upload`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadAttachmentContentMutationOptions = <TData = Awaited<ReturnType<typeof uploadAttachmentContent>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;attachmentId: string;data: UploadAttachmentContentBody}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['uploadAttachmentContent'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadAttachmentContent>>, {id: string;attachmentId: string;data: UploadAttachmentContentBody}> = (props) => {
          const {id,attachmentId,data} = props ?? {};

          return  uploadAttachmentContent(id,attachmentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;attachmentId: string;data: UploadAttachmentContentBody}, TContext>}

    export type UploadAttachmentContentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadAttachmentContent>>>
    export type UploadAttachmentContentMutationBody = UploadAttachmentContentBody
    export type UploadAttachmentContentMutationError = ErrorType<ProblemDetails>

    export const useUploadAttachmentContent = <TData = Awaited<ReturnType<typeof uploadAttachmentContent>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;attachmentId: string;data: UploadAttachmentContentBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string;attachmentId: string;data: UploadAttachmentContentBody},
        TContext
      > => {

      const mutationOptions = getUploadAttachmentContentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the content of an attachment
 */
export const downloadAttachmentContent = (
    id: string,
    attachmentId: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<Blob>(
      {url: `/requests/${id}/attachments/${attachmentId}/download`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getDownloadAttachmentContentQueryKey = (id: string,
    attachmentId: string,) => {
    return [`/requests/${id}/attachments/${attachmentId}/download`] as const;
    }

    
export const getDownloadAttachmentContentQueryOptions = <TData = Awaited<ReturnType<typeof downloadAttachmentContent>>, TError = ErrorType<ProblemDetails>>(id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadAttachmentContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadAttachmentContentQueryKey(id,attachmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadAttachmentContent>>> = ({ signal }) => downloadAttachmentContent(id,attachmentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && attachmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadAttachmentContent>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type DownloadAttachmentContentQueryResult = NonNullable<Awaited<ReturnType<typeof downloadAttachmentContent>>>
export type DownloadAttachmentContentQueryError = ErrorType<ProblemDetails>


export function useDownloadAttachmentContent<TData = Awaited<ReturnType<typeof downloadAttachmentContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadAttachmentContent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadAttachmentContent>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadAttachmentContent<TData = Awaited<ReturnType<typeof downloadAttachmentContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadAttachmentContent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadAttachmentContent>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadAttachmentContent<TData = Awaited<ReturnType<typeof downloadAttachmentContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadAttachmentContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useDownloadAttachmentContent<TData = Awaited<ReturnType<typeof downloadAttachmentContent>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadAttachmentContent>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getDownloadAttachmentContentQueryOptions(id,attachmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



